body {
  margin: 0;
  font-family: 'Roboto Flex', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(0, 0, 0, 0.6);
}

svg {
  color: inherit;
  width: 30px;
  height: 30px;
}
