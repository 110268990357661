.app {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 100px 0;
  box-sizing: border-box;
}

.poster-card {
  border-radius: 8px;
  border: 1px solid rgb(0, 0, 0, 0.2);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
  height: 300px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.poster-card > img {
  max-height: 100%;
  max-width: 100%;
}

.playback-actions {
  display: flex;
  align-items: center;
}

.playback-actions > :not(:last-child) {
  margin-right: 20px;
}

.playback-button {
  background-color: white;
  border: 1px solid rgb(0, 0, 0, 0.2);
  color: rgb(0, 0, 0, 0.6);
  border-radius: 100%;
  padding: 20px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
  transition: box-shadow ease 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.playback-button:hover:not(:disabled) {
  box-shadow: 7px 7px 5px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 7px 7px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 7px 7px 5px 0px rgba(0, 0, 0, 0.2);
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
